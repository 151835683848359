<template>
    <div class="authorization-page">

        <div class="form">
            <router-link :to="{name: 'Home'}">
                <img :src="require('@/assets/img/logo.png')" class="logo" alt="EURECBROKER">
            </router-link>

            <h1>{{ $t("Register") }}</h1>

            <form @submit.prevent="register" id="w0" class="zutzu-form">
                <div v-if="!isInvited && !$route.query.email" class="user-type-select">
                    <button type="button"
                            :class="[user.user_type === 'Candidate' ? 'active' : '', 'btn btn-link']"
                            @click="user.user_type = 'Candidate'">{{ $t('As a Candidate') }}
                    </button>
                    <button type="button"
                            :class="[user.user_type === 'Client' ? 'active' : '', 'btn btn-link']"
                            @click="user.user_type = 'Client'">{{ $t('As a Client') }}
                    </button>
                </div>

                <div class="social-signup">
                    <router-link :to="{ name: 'SocialAuthorizationRedirect', params: { network: 'facebook' } }">
                        <button class="btn btn-social-auth" type="button">
                            <img src="@/assets/icon/facebook.png" alt="facebook" />
                        </button>
                    </router-link>

                    <router-link :to="{ name: 'SocialAuthorizationRedirect', params: { network: 'google' } }">
                        <button class="btn btn-social-auth mid-btn" type="button">
                            <img src="@/assets/icon/google.png" alt="google" />
                        </button>
                    </router-link>

                    <router-link :to="{ name: 'SocialAuthorizationRedirect', params: { network: 'linkedin' } }">
                        <button class="btn btn-social-auth" type="button">
                            <img src="@/assets/icon/linkedin.png" alt="linkedin" />
                        </button>
                    </router-link>
                </div>

                <div v-if="user.user_type === 'Client'" class="form-group">
                    <input 
                        v-model="user.company.name"
                        name="company"
                        type="text"
                        :class="['form-control', {'has-error': errors[`company.name`]}]"
                        :placeholder="$t('Company name')"
                    >
                    <FormErrors v-if="errors[`company.name`]" :errors="errors[`company.name`]"  />
                </div>

                <div class="form-group">
                    <input
                        v-model="user.profile.first_name"
                        name="name"
                        type="text"
                        :class="['form-control', {'has-error': errors['profile.first_name']}]"
                        :placeholder="$t('First name')"
                    >

                    <FormErrors v-if="errors['profile.first_name']" :errors="errors['profile.first_name']"  />
                    
                </div>

                <div class="form-group">
                    <input
                        v-model="user.profile.last_name"
                        name="surname"
                        type="text"
                        :class="['form-control', {'has-error': errors['profile.last_name']}]"
                        :placeholder="$t('Last name')"
                    >
                    
                    <FormErrors v-if="errors['profile.last_name']" :errors="errors['profile.last_name']"  />

                </div>

                <div class="form-group">
                    <input
                        v-model="user.email"
                        type="email"
                        :class="['form-control', {'has-error': errors[`email`]}]"
                        :placeholder="$t('Email')"
                        :disabled="$route.query.email"
                    >
                    
                    <FormErrors v-if="errors[`email`]" :errors="errors[`email`]"  />

                </div>

                <div class="form-group phone-input-group">
                    <PhoneInput 
                        :classList="[{'has-error': errors['profile.phone_number']}]"
                        @update="setPhone"
                        :phone="user.profile.phone_number"
                    />
                    
                    <FormErrors v-if="errors['profile.phone_number']" :errors="errors['profile.phone_number']"  />

                </div>

                <div class="form-group">
                    <input
                        v-model="user.password"
                        type="password"
                        :class="['form-control', {'has-error': errors[`password`]}]"
                        :placeholder="$t('Password')"
                    >
                    
                    <FormErrors v-if="errors[`password`]" :errors="errors[`password`]"  />
                </div>

                <div class="form-group">
                    <input
                        v-model="user.password_confirmation"
                        type="password"
                        class="form-control"
                        :placeholder="$t('Repeat password')"
                    >
                </div>

                <div class="form-group">

                    <Multiselect
                        v-model="user.profile.language_id"
                        :options="languages"
                        :placeholder="$t('Select language')"
                        :class="['form-control', {'has-error': errors['profile.language_id']}]"
                        :searchable="true"
                    />

                    <FormErrors v-if="errors['profile.language_id']" :errors="errors['profile.language_id']"  />

                </div>

                <div class="form-group">
                    <Multiselect
                        v-model="user.profile.country_id"
                        :options="countries"
                        :placeholder="$t('Select country')"
                        :class="['form-control', {'has-error': errors['profile.country_id']}]"
                        :searchable="true"
                    />

                    <FormErrors v-if="errors['profile.country_id']" :errors="errors['profile.country_id']"  />

                </div>

                <div class="checkbox-container" @click="toggleAgree">
                    <div :class="['checkbox', {'checked': user.profile.agree_terms}]"></div>
                    <label>{{ $t('By signing up you agree to our terms.') }}</label>
                </div>

                <button type="submit" class="btn btn-primary w-100" :disabled="disabled">
                    {{ $t('Register') }}
                </button>

                <p class="already-an-account">{{ $t('Already a member?') }}
                    <router-link to="/login">{{ $t('Log In') }}</router-link>
                </p>
            </form>
        </div>
        <div class="side">
            <img :src="require('@/assets/img/background-authorization-page.png')" :alt="$t('RecBroker authorization image')" />
            <div class="circle black"></div>
            <div class="circle blue"></div>
        </div>
    </div>
</template>

<script>
import {userService} from '@/services/userService'
import {locationService} from '@/services/locationService'
import FormErrors from "@/components/widgets/FormErrors";
import Multiselect from '@vueform/multiselect'
import PhoneInput from '@/components/widgets/PhoneInput';

export default {
    name: "Register",
    components: {
        FormErrors,
        Multiselect,
        PhoneInput
    },
    data() {
        return {
            user: {
                company_name: '',
                signature: this.$route.query.signature,
                group_id: this.$route.query.group_id,
                email: this.$route.query.email,
                profile: {},
                company: {},
                user_type: 'Candidate',
            },
        }
    },
    mounted() {
        locationService.getLocation()
            .then(response => {
                this.user.profile.language_id = response.data.language_id;
                this.user.profile.country_id = response.data.country_id;
                this.user.profile.phone_number = response.data.phone_code;
            });
    },
    computed: {
        isInvited() {
            return this.$route.query.signature !== undefined
                && this.$route.query.group_id !== undefined
                && this.$route.query.email !== undefined
        },
        countries() {
            return this.$store.getters.countries
        },
        languages() {
            return this.$store.getters.languages
        }
    },
    methods: {
        setPhone(phone) {
            this.user.profile.phone_number = phone;
        },
        toggleAgree() {
            this.user.profile.agree_terms = this.user.profile.agree_terms ? false : true;
        },
        register() {
            this.disabled = true

            let uriParams = {};
            if (this.isInvited) {
                uriParams = {
                    email: this.user.email,
                    group_id: this.user.group_id,
                    signature: this.user.signature,
                }
            }

            userService.register(this.user, uriParams)
                .then(response => {
                    this.errors = []
                    this.$store.dispatch('setToken', response.data.access_token)
                    this.$router.push({ name: 'Onboarding' })
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                    if (this.errors.group_id)
                        alert(this.errors.group_id[0])
                })
                .finally(() => this.disabled = false)

        },
        hideRegisterForm() {
            this.$router.push({name: 'Home'})
        },
    },
}
</script>